import { Grid } from '@mui/material';
import ListItemViewer from './ListItemViewer';

function ListUsers(props: { users: any; myUser: any }) {
    const { users, myUser } = props
    return (
        <Grid container>
            {users.map((user: { username: string; avatarUrl: string; id: any; }, index: number) => {
                if (user) {
                    return <ListItemViewer username={user.username} avatar={user.avatarUrl} isMe={user.id === myUser?.id} role={"Owner"} />
                } else {
                    return <ListItemViewer username={`User ${index}`} role={"Owner"} />
                }
            })}
        </Grid>)
}

export default ListUsers