import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function VODPlayer(props: { time: string; seekToggle: number; playStatus: boolean; handleSendMessage: any, vods: any[], currentTab: number, playerRef: any, seekLockRef: any, playingStateRef: any }) {
    const { vods, currentTab, time, seekToggle, playStatus, handleSendMessage, seekLockRef, playingStateRef, playerRef } = props

    const prevTab = usePrevious(currentTab)

    //playerRef.current?.seekTo(Number(time), "seconds");

    useEffect(() => {
        //seek the video
        seekLockRef.current = true;
        playerRef.current?.seekTo(Number(time), "seconds");
        setTimeout(() => { seekLockRef.current = false }, 300)
    }, [seekToggle])

    useEffect(() => {
        //seek the video
        playingStateRef.current = true;

        setTimeout(() => { playingStateRef.current = false }, 300)
    }, [playStatus])

    useEffect(() => {
        if (prevTab && currentTab && playerRef.current) {

            //seekLockRef.current = true;
            //playerRef.current?.seekTo(vods[currentTab].startTimestamp - vods[prevTab].startTimestamp + playerRef.current.getDuration(), "seconds");
            //handleSendMessage(JSON.stringify({ type: "SEEKTO", value: vods[currentTab].startTimestamp - vods[prevTab].startTimestamp + playerRef.current.getDuration() }))
            ///setTimeout(() => { seekLockRef.current = false }, 300)
        }
    }, [currentTab])

    const onPlayHandler = () => {
        if (!playingStateRef.current)
            handleSendMessage(JSON.stringify({ type: "PLAYINGSTATE", value: true }))
    }

    const onPauseHandler = () => {
        if (!playingStateRef.current)
            handleSendMessage(JSON.stringify({ type: "PLAYINGSTATE", value: false }))
    }

    const onSeekHandler = (e: any) => {
        console.log("Seeklock: ", seekLockRef.current)

        if (!seekLockRef.current) {
            handleSendMessage(JSON.stringify({ type: "SEEKTO", value: e.position }))
        }
        console.log('onSeek', e)
        console.log('time', time)
    }

    const onReady = () => {
        console.log('ready to seek')
        console.log("Seeklock: ", seekLockRef.current)
        playerRef.current?.seekTo(Number(time), "seconds");
        setTimeout(() => { seekLockRef.current = false }, 300)
        console.log("Seeklock: ", seekLockRef.current)
    }

    return (<ReactPlayer onPlay={onPlayHandler} onPause={onPauseHandler} onSeek={onSeekHandler} onReady={onReady} playing={playStatus} ref={playerRef} disableDeferredLoading={true} height='100%' width='100%' url={vods[currentTab].url} controls config={{
        twitch: {
            options: {
                time: time,
                autoplay: true,
                parent: ['home.illidra.com', 'illidra.com', '3764-24-115-150-90.ngrok.io']
            }
        }
    }
    } />)
}

export default VODPlayer;