import { OpenInNew } from '@mui/icons-material';
import { Button, ButtonGroup, Collapse, List, ListItem, Stack } from '@mui/material';
import React from 'react';

type ListItemWipeProps = {
    title: string,
    onClick: Function,
    timestamp: number,
    id: string,
    FFLogsUrl: string;
    disableVodButton: boolean;
    adjustTimestamp: Function;
    vod: {};
    mechanics: any[];
};

const ListItemWipe: React.FunctionComponent<ListItemWipeProps> = (props) => {

    const { title, onClick, id, FFLogsUrl, disableVodButton, adjustTimestamp, mechanics } = props;

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <ListItem id={id} sx={{ padding: '0px', width: '100%' }} disablePadding className='ListItemWipe'>
            <Stack sx={{ width: '100%' }} direction={'column'}>
                <ButtonGroup orientation='horizontal' sx={{ width: '100%' }}>
                    <Button sx={{ width: '100%', fontSize: '13px' }} disabled={!disableVodButton} onClick={handleClick}>{title}</Button>
                    <Button onClick={() => window.open(`${FFLogsUrl}#fight=${id}`)} variant='contained' className='ListItemWipeSecondaryButton'><OpenInNew /></Button>
                </ButtonGroup>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ButtonGroup orientation='vertical' sx={{ width: '100%' }}>
                            {mechanics.map((mech) => {
                                return (<Button style={{ fontSize: '16px' }} fullWidth onClick={() => onClick(JSON.stringify({ type: "SEEKTO", value: adjustTimestamp(mech.timestamp) }))} variant='contained' className='ListItemWipeSecondaryButton'>{mech.name}</Button>)
                            })}
                        </ButtonGroup>
                    </List>
                </Collapse>
            </Stack>
        </ListItem>
    )
}

export default ListItemWipe;