import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { AppBar, Button, ButtonGroup, createTheme, Grid, Input, List, Menu, MenuItem, Stack, Tab, Tabs, ThemeProvider, Typography } from '@mui/material';
import { Send, OpenInNew, Add } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ListItemWipe from './components/ListItemWipe';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebSocketMessage } from 'react-use-websocket/dist/lib/types';
import VODPlayer from './components/VODPlayer';
import { LoadingButton } from '@mui/lab';
import ReactPlayer from 'react-player';
import { useLoaderData } from 'react-router-dom';
import ListUsers from './components/ListUsers';
import DiscordLogin from './components/DiscordLogin';
import { useCookies } from 'react-cookie';
import { getCookie } from 'react-use-cookie';

function App(props: { user: any, setUser: Function, setSocketUrl: Function, sendMessage: Function, lastMessage: MessageEvent<any> | null }) {
  console.log("loaderdata", useLoaderData());

  const { user, setUser/*, setSocketUrl, sendMessage, lastMessage */} = props

  const { roomId } = useLoaderData() as { roomId: string };

  const { sendMessage, lastMessage, readyState } = useWebSocket(`wss://${process.env.REACT_APP_API_URL}/${roomId}`, { shouldReconnect: () => true });

  //const ws = useRef<WebSocket | null>(null);

  //const [lastMessage, setLastMessage] = useState({})
  const [userToken, setToken, removeToken] = useCookies(['token']);
  const [videoTime, setVideoTime] = useState('0')
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [seekToggle, setSeekToggle] = useState(0)
  const [tabValue, setTabValue] = React.useState(0);
  const [FFLogsUrl, setFFLogsUrl] = React.useState('');
  const [addVodURL, setAddVodURL] = React.useState('');
  const [FFLogsLoading, setFFLogsLoading] = React.useState(false);
  const [addVodURLLoading, setAddVodURLLoading] = React.useState(false);
  const [FFLogsPulls, setFFLogsPulls] = React.useState<[]>([]);
  const [FFLogsStartTimestamp, setFFLogsStartTimestamp] = React.useState(0);
  const [vods, setVods] = React.useState<any[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [users, setUsers] = useState<any[]>([])

  const open = anchorEl?.id;

  const seekLockRef = useRef(true)
  const playingStateRef = useRef(true)
  const playerRef = useRef<ReactPlayer>(null);
  const prevToken = useRef(userToken)

  useEffect(() => {
    if (lastMessage !== null) {
      const jsonMessage = JSON.parse(lastMessage.data)
      switch (jsonMessage.type) {
        case "NEWTIME":
          setVideoTime(jsonMessage.value)
          seekLockRef.current = true;
          setSeekToggle(Date.now())
          console.log("setVideoTime", videoTime)
          break;
        case "FFLOGS":
          setFFLogsLoading(false)
          setFFLogsPulls(jsonMessage.value.reportDict.sort((a: { duration: number; }, b: { duration: number; }) => b.duration - a.duration));
          setFFLogsUrl(`https://www.fflogs.com/reports/${jsonMessage.value.code}`)
          setFFLogsStartTimestamp(jsonMessage.value.startTimestamp)
          console.log("FFLOGS", jsonMessage.value);
          break;
        case "VOD":
          setVods(vods.concat(jsonMessage.value));
          setAddVodURLLoading(false);
          console.log("VOD", jsonMessage.value);
          console.log("vods", vods);
          break;
        case "CHANGETAB":
          console.log("CHANGETAB", jsonMessage.value);
          if (jsonMessage.value !== undefined && tabValue !== undefined && playerRef.current) {
            let seekval = (vods[tabValue].startTimestamp - vods[jsonMessage.value].startTimestamp) / 1000 + playerRef.current.getCurrentTime();
            console.log(`tab adjust ${seekval}, diff = ${(vods[tabValue].startTimestamp - vods[jsonMessage.value].startTimestamp) / 1000}, current time = ${playerRef.current.getCurrentTime()}`)

            setTimeout(() => { sendMessage(JSON.stringify({ type: "SEEKTO", value: seekval })) }, 1000)
          }
          setTabValue(jsonMessage.value);
          break;
        case "PLAYINGSTATE":
          playingStateRef.current = true;
          setTimeout(() => { playingStateRef.current = false }, 200)
          console.log("PLAYINGSTATE", jsonMessage.value);
          setVideoPlaying(jsonMessage.value);
          break;
        case "STATE":
          console.log("STATE", jsonMessage.value);
          let receivedState = jsonMessage.value
          seekLockRef.current = true;
          setVods(receivedState.vods)
          setTabValue(receivedState.currentTab)
          if (receivedState.fflogs) {
            setFFLogsPulls(receivedState.fflogs.reportDict.sort((a: { duration: number; }, b: { duration: number; }) => b.duration - a.duration))
            setFFLogsStartTimestamp(receivedState.fflogs.startTimestamp)
          } else {
            setFFLogsPulls([])
            setFFLogsStartTimestamp(0)
          }
          setVideoTime(receivedState.time)
          setVideoPlaying(receivedState.playingState)
          if (receivedState.fflogs?.code) {
            setFFLogsUrl(`https://www.fflogs.com/reports/${receivedState.fflogs.code}`)
          }
          if (receivedState.users) {
            setUsers(receivedState.users)
          }
          break;
        case "USERS":
          console.log("USERS", jsonMessage.value)
          setUsers(jsonMessage.value)
          break;
        case "USER":
          console.log("USER", jsonMessage.value)
          console.log("USER2", user)
          if (user?.id !== jsonMessage.value?.id)
            setUser(jsonMessage.value)
          sendMessage(JSON.stringify({ type: "REQUESTSTATE", value: true }))
          break;
      }
    }
  }, [lastMessage]);
  /*
    useEffect(() => {
      const socket = new WebSocket(`wss://${process.env.REACT_APP_API_URL}/${roomId}`);
  
      socket.onopen = () => {
        console.log("opened");
      };
  
      socket.onclose = () => {
        console.log("closed");
      };
  
      socket.onmessage = (event) => {
        console.log("got message", event.data);
        setLastMessage(event.data);
      };
  
      ws.current = socket;
  
      return () => {
        socket.close();
      };
    })*/

  useEffect(() => {
    //setSocketUrl(`wss://${process.env.REACT_APP_API_URL}/${roomId}`)
    sendMessage(JSON.stringify({ type: "REQUESTSTATE", value: true }))
    setInterval(() => {
      let getCookieJSON: any = {}
      try {
        getCookieJSON = JSON.parse(getCookie("token"))
      } catch (e) { }
      if (prevToken?.current?.token?.access_token !== getCookieJSON?.access_token) {
        prevToken.current = userToken
        setToken("token", userToken, { domain: process.env.REACT_APP_DOMAIN })
        sendMessage(JSON.stringify({ type: "AUTH", value: getCookieJSON?.access_token }))
      }
    }, 1000)
  }, [])

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    sendMessage(JSON.stringify({ type: "CHANGETAB", value: newValue }))
    //setTabValue(newValue);
  };

  const handleSendMessage = useCallback((test: WebSocketMessage) => sendMessage(test), []);

  const handleSubmitFFLogsUrl = (event: any) => {
    event.preventDefault()
    setFFLogsLoading(true)
    sendMessage(JSON.stringify({ type: "FFLOGS", value: FFLogsUrl }))
    return false
  }

  const handleSubmitVOD = (event: any) => {
    event.preventDefault()
    setAddVodURLLoading(true)
    sendMessage(JSON.stringify({ type: "ADDVOD", value: addVodURL }))
    setAddVodURL('')
    return false
  }

  const FFLogsElement = (props: { fflogsPulls: [], selectedVod: number, fflogsStartTimestamp: number }) => {
    const { fflogsPulls, selectedVod, fflogsStartTimestamp } = props;
    const adjustTimestamp = (pullTimestamp: number) => {
      if (!vods[selectedVod]) {
        return 0;
      }
      //pull.mechanics[pull.mechanics.length - 1].timestamp
      return (fflogsStartTimestamp + pullTimestamp - vods[selectedVod].startTimestamp) / 1000;
    }

    /*const pullsAdjustedTimestamps = fflogsPulls.map((pull: any) => {
      //return (pull.timestamp - vods[selectedVod].startTimestamp ) / 1000;
      if (!vods[selectedVod]) {
        return 0;
      }
      return (fflogsStartTimestamp + pull.mechanics[pull.mechanics.length - 1].timestamp - vods[selectedVod].startTimestamp) / 1000;
    })*/
    return (
      <Grid container>
        {
          fflogsPulls.map((pull: any, index) => {
            const newTimestamp = adjustTimestamp(pull.mechanics[pull.mechanics.length - 1].timestamp);

            return <ListItemWipe mechanics={pull.mechanics} adjustTimestamp={adjustTimestamp} vod={vods[selectedVod]} disableVodButton={newTimestamp > 0 && newTimestamp < vods[selectedVod].videoDuration} FFLogsUrl={FFLogsUrl} id={pull.fightId} onClick={sendMessage} title={`Pull ${pull.fightId} (${Math.floor(pull.duration / 1000 / 60)}:${String(Math.floor(pull.duration / 1000 % 60)).padStart(2, '0')})`} timestamp={newTimestamp} />
          })}
      </Grid>
    )
  }
  const handleLogOut = (a: Function) => {
    console.log("logging out")
    removeToken("token", { domain: process.env.REACT_APP_DOMAIN });
    a();
  }
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (

    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <AppBar className='Appbar' color='secondary' position='sticky'>
          <Stack sx={{ height: "100%" }} justifyContent='space-between' direction="row">
            <Stack sx={{ height: "100%" }} direction="row">
              <Tabs className='TabsContainer' value={tabValue} onChange={handleTabChange}>
                {vods.map((vod, i) =>
                  <Tab sx={{ height: "100%" }} key={i} value={i} label={vod.broadcaster} />
                )}
              </Tabs>
              <Button id={'addvod'} sx={{ borderRadius: '0px' }} startIcon={<AddIcon />} variant='contained' onClick={handleOpenMenu}><Typography>Add VOD</Typography></Button>
              <Menu
                anchorEl={anchorEl}
                open={open === 'addvod'}
                onClose={handleCloseMenu}
              >
                <MenuItem sx={{ paddingLeft: '12px', paddingRight: '12px' }} disableRipple disableTouchRipple><form name='vodurl' style={{ position: 'relative', right: '0px' }} onSubmit={handleSubmitVOD}>
                  <Input disabled={addVodURLLoading} type="url" value={addVodURL} sx={{ width: '375px', paddingLeft: '10px', height: '100%' }} onChange={(event) => { setAddVodURL(event.target.value) }} endAdornment={
                    <LoadingButton type='submit' variant='contained' loading={addVodURLLoading === true} sx={{ height: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} ><Send /></LoadingButton>
                  } /></form>
                </MenuItem>
              </Menu>
            </Stack>
            <DiscordLogin handleLogOut={handleLogOut} roomId={roomId} user={user} />
          </Stack>
        </AppBar>

        <Grid className='Appbody' container>
          <Grid className='ViewerList' position='relative' style={{ left: '0px' }}>
            <List>
              <ListUsers users={users} myUser={user} />
            </List>
          </Grid>
          <Grid className='Player'>
            {vods[tabValue]?.url && <VODPlayer playerRef={playerRef} playingStateRef={playingStateRef} seekLockRef={seekLockRef} vods={vods} currentTab={tabValue} time={videoTime} seekToggle={seekToggle} playStatus={videoPlaying} handleSendMessage={handleSendMessage} />}
          </Grid>
          <Grid className='PullList' position='relative' style={{ right: '0px' }}>
            <List disablePadding>
              <ButtonGroup fullWidth>
                <Button id={'addreport'} sx={{ height: '100%', borderRadius: '0px' }} variant='contained' onClick={handleOpenMenu}><Add /></Button>
                {FFLogsUrl && <Button variant='contained' onClick={() => window.open(`${FFLogsUrl}`)} sx={{ height: '100%', borderRadius: '0px' }}><OpenInNew /></Button>}
              </ButtonGroup>
              <Menu
                anchorEl={anchorEl}
                open={open === 'addreport'}
                onClose={handleCloseMenu}
              >
                <MenuItem sx={{ paddingLeft: '12px', paddingRight: '12px' }} disableRipple disableTouchRipple>
                  <form name='fflogsurl' style={{ position: 'relative', right: '0px' }} onSubmit={handleSubmitFFLogsUrl}>
                    <Input type="url" value={FFLogsUrl} onChange={(event) => {
                      setFFLogsUrl(event.target.value)
                    }} sx={{ width: '460px', paddingLeft: '10px', height: '100%' }} endAdornment={<ButtonGroup orientation='vertical' sx={{ height: '100%' }}>
                      <LoadingButton type='submit' variant='contained' loading={FFLogsLoading === true} sx={{ height: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} ><Send /></LoadingButton>

                    </ButtonGroup>} />
                  </form>
                </MenuItem>
              </Menu>
              <FFLogsElement selectedVod={tabValue} fflogsPulls={FFLogsPulls} fflogsStartTimestamp={FFLogsStartTimestamp} />
            </List>
          </Grid>
        </Grid>
      </div>

    </ThemeProvider >

  );
}

export default App;

export function roomLoader(a: { params: any }) {
  const roomId = a.params.roomId;
  return { roomId: roomId };
}
